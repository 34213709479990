<template>
  <v-dialog
    v-model="dialog"
    max-width="1000px"
    :persistent="load"
    scrollable
  >
    <v-card
      :disabled="load"
    >
      <v-card-title>
        {{ copiar ? 'Copiar objetivos prox mes' : 'Editar objetivos' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-6">
          <v-col cols="6" md="3" class="py-0">
            Periodo
            <FechaPickerMes
              :fecha.sync="periodo"
              :clearable="false"
              :disabled="true"
            />
          </v-col>
          <v-col v-if="copiar" cols="6" md="3" class="py-0">
            Copiar para
            <FechaPickerMes
              :fecha.sync="siguiente_periodo"
              :clearable="false"
              :disabled="true"
            />
          </v-col>
          <v-col cols="6" md="3" class="py-0">
            Mantener fijo
            <v-autocomplete
              v-model="fijo"
              append-outer-icon="fas fa-lock"
              :disabled="bloquear"
              :items="valores"
              hide-details
              outlined
              dense
              @change="bloquear_campo"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-data-table
          class="cebra mt-6"
          item-key="id"
          :items-per-page="-1"
          :headers="headers"
          :items="objetivos"
          :loading="load"
          hide-default-footer
          dense
        >
          <template v-slot:[`item.cant_vend`]="{ item }">
            <v-text-field
              v-model.trim="item.cant_vend"
              class="my-2"
              type="number"
              tabindex="1"
              :disabled="bloquear"
              :readonly="lock_cant_vend"
              :filled="lock_cant_vend"
              hide-details
              dense
              @blur="pierde_foco(item, 1)"
            ></v-text-field>
          </template>
          <template v-slot:[`item.q_vend`]="{ item }">
            <v-text-field
              v-model.trim="item.q_vend"
              class="my-2"
              type="number"
              prefix="$"
              tabindex="1"
              :disabled="bloquear"
              :readonly="lock_q_vend"
              :filled="lock_q_vend"
              hide-details
              dense
              @blur="pierde_foco(item, 2)"
            ></v-text-field>
          </template>
          <template v-slot:[`item.obj_pv`]="{ item }">
            <v-text-field
              v-model.trim="item.obj_pv"
              class="my-2"
              type="number"
              prefix="$"
              tabindex="1"
              :disabled="bloquear"
              :readonly="lock_obj_pv"
              :filled="lock_obj_pv"
              hide-details
              dense
              @blur="pierde_foco(item, 0)"
            ></v-text-field>
          </template>
          <template v-slot:[`item.icon`]="{ item }">
            <v-icon
              :title="item.title ? item.title : 'Pendiente'"
              :color="item.color"
              small
            >
              {{ item.icono ? item.icono : 'far fa-clock' }}
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              :disabled="bloquear"
              title="Quitar"
              color="error"
              small
              @click="quitar(item)"
            >
              fas fa-times-circle
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          :disabled="bloquear || objetivos.length == 0"
          @action="copiar ? copiar_mes() : editar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Componente para editar o copiar prox mes
 *  los objetivos de los puntos de venta
 */
import { get_next_periodo } from '../../util/utils'
import FechaPickerMes from '../util/FechaPickerMes'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      bloquear: false,
      lock_cant_vend: true,
      lock_q_vend: false,
      lock_obj_pv: false,
      periodo: null,
      siguiente_periodo: null,
      fijo: 1,
      valores: [
        { value: 1, text: 'Cant Vend' },
        { value: 2, text: 'Q Vend' },
        { value: 0, text: 'Obj PV' },
      ],
      objetivos: [],
      headers: [
        { text: 'Sucursal', value: 'sucursal_nom' },
        { text: 'Pto Vta', value: 'local_nom', divider: true },
        { text: 'Cant Vend', value: 'cant_vend', align: 'end' },
        { text: 'Q Vend', value: 'q_vend', align: 'end' },
        { text: 'Obj PV', value: 'obj_pv', align: 'end', divider: true },
        { text: 'Tipo', value: 'tipo_nom' },
        { text: 'Estado', value: 'icon', sortable: false, align: 'center'},
        { text: '', value: 'actions', sortable: false },
      ],
    }
  },
  props: {
    value: Boolean,
    all_ptosvta: Array, // todos los objetivos filtrados en el index
    ptosvta: Array,   // ptos de venta seleccionados en el index
    copiar: {         // copiar sirve para indicar que se van a copiar los objetivos del prox mes
      type: Boolean,
      default: false, // si es falso significa que se van a editar los objetivos seleccinados
    },
  },
  components: {
    FechaPickerMes,
    BtnConfirmar,
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    all_obj_ptosvta: {
      get () {
        return this.all_ptosvta
      },
      set (value) {
        this.$emit('update:all_ptosvta', value)
      }
    },
  },
  watch: {
    dialog (val) {
      if (val) {
        this.objetivos = JSON.parse(JSON.stringify(this.ptosvta))
        this.periodo = this.objetivos[0].periodo
        this.siguiente_periodo = get_next_periodo(this.periodo)
        this.fijo = 1
        this.bloquear = false
        this.bloquear_campo()

        // formatea los valores
        this.objetivos.forEach(item => {
          item.cant_vend = parseFloat(item.cant_vend).toFixed(2)
          item.q_vend = parseFloat(item.q_vend).toFixed(2)
          item.obj_pv = parseFloat(item.obj_pv).toFixed(2)
        })
      }
    }
  },
  methods: {
    async copiar_mes () {
      this.load = true
      this.bloquear = true
      let errores = 0

      for (let index = 0; index < this.objetivos.length; index++) {
        const item = this.objetivos[index]

        await this.$store.dispatch('objetivos/nuevo_obj_ptovta', {
          periodo: this.siguiente_periodo,
          tipo: item.tipo_cod,
          ptovta: item.local_cod,
          objetivo: item.obj_pv,
          vendedores: item.cant_vend,
        })
          .then(() => {
            // setea la row con un icono de exito
            item.icono = 'fas fa-check'
            item.color = 'success'
            item.title = 'Correcto'
          })
          .catch(error => {
            // setea la row con una advertencia y el mensaje de error
            item.icono = 'fas fa-exclamation-triangle'
            item.color = 'warning'
            item.title = error.message
            errores += 1
          })

      }
      this.load = false

      // mensaje personalizado
      let icono = 'success'
      let mensaje = `Copia de objetivos prox mes finalizada correctamente <p style="font-size: 22px; margin-top: 12px">
                    Se copiaron ${this.objetivos.length} objetivos para ${this.siguiente_periodo}</p>`
      if (errores > 0) {
        icono = 'warning'
        mensaje = `Copia de objetivos prox mes finalizada, revise el estado de cada objetivo
                  <p style="font-size: 22px; margin-top: 12px; margin-bottom: 0px">Correctos: ${this.objetivos.length - errores} <br/> Con error: ${errores}</p>`
      }
      this.$swal.fire({
        icon: icono,
        title: mensaje
      })

    },
    async editar () {
      this.load = true
      this.bloquear = true
      let errores = 0

      for (let index = 0; index < this.objetivos.length; index++) {
        const item = this.objetivos[index]

        await this.$store.dispatch('objetivos/editar_obj_ptovta', {
          id: item.id,
          objetivo: item.obj_pv,
          vendedores: item.cant_vend,
        })
          .then(() => {
            // edita el item en la lista
            let index_obj = this.all_obj_ptosvta.indexOf(this.all_obj_ptosvta.find(obj => obj.id == item.id))
            this.all_obj_ptosvta[index_obj].cant_vend = item.cant_vend
            this.all_obj_ptosvta[index_obj].q_vend = item.q_vend
            this.all_obj_ptosvta[index_obj].obj_pv = item.obj_pv

            // setea la row con un icono de exito
            item.icono = 'fas fa-check'
            item.color = 'success'
            item.title = 'Correcto'
          })
          .catch(error => {
            // setea la row con una advertencia y el mensaje de error
            item.icono = 'fas fa-exclamation-triangle'
            item.color = 'warning'
            item.title = error.message
            errores += 1
          })

      }
      this.load = false

      // vuelve a calcular los totales (el metodo lo hace el padre)
      this.$emit('action')

      // mensaje personalizado
      let icono = 'success'
      let mensaje = `Modificación de objetivos finalizada correctamente <p style="font-size: 22px; margin-top: 12px">
                    Se editaron ${this.objetivos.length} objetivos</p>`
      if (errores > 0) {
        icono = 'warning'
        mensaje = `Modificación de objetivos finalizada, revise el estado de cada objetivo
                  <p style="font-size: 22px; margin-top: 12px; margin-bottom: 0px">Correctos: ${this.objetivos.length - errores} <br/> Con error: ${errores}</p>`
      }
      this.$swal.fire({
        icon: icono,
        title: mensaje
      })

    },
    quitar (item) {
      const index = this.objetivos.indexOf(item)
      this.objetivos.splice(index, 1)
    },
    /**
     * Nota: siempre va a haber un input fijo, este metodo calcula el valor
     *       de un input en base al input bloqueado (fijo) y al input que
     *       perdio el foco, que corresponde al valor editado.
     * 
     *       'val' hace referencia al input que perdio el foco, utiliza
     *       los mismos valores de referencia que los inputs fijos:
     * 
     *       0 para obj_pv, 1 para cant_vend y 2 para q_vend
     */
    pierde_foco (item, val) {
      switch (this.fijo) {
        // obj_pv esta fijo
        case 0:
          switch (val) {
            // cant_vend perdio el foco
            case 1:
              this.calcular_q_vend(item)
              break
            
            // q_vend perdio el foco
            case 2:
              this.calcular_cant_vend(item)
              break
          }
          break

        // cant_vend esta fijo
        case 1:
          switch (val) {
            // obj_pv perdio el foco
            case 0:
              this.calcular_q_vend(item)
              break
            
            // q_vend perdio el foco
            case 2:
              this.calcular_obj_pv(item)
              break
          }
          break

        // q_vend esta fijo
        case 2:
          switch (val) {
            // obj_pv perdio el foco
            case 0:
              this.calcular_cant_vend(item)
              break
            
            // cant_vend perdio el foco
            case 1:
              this.calcular_obj_pv(item)
              break
          }
          break
      }
    },
    calcular_obj_pv (item) {
      if (item.cant_vend && item.q_vend) {
        item.obj_pv = parseFloat(item.cant_vend * item.q_vend).toFixed(2)
      }
    },
    calcular_cant_vend (item) {
      if (item.obj_pv && item.q_vend) {
        item.cant_vend = parseFloat(item.obj_pv / item.q_vend).toFixed(2)
      }
    },
    calcular_q_vend (item) {
      if (item.obj_pv && item.cant_vend) {
        item.q_vend = parseFloat(item.obj_pv / item.cant_vend).toFixed(2)
      }
    },
    bloquear_campo () {
      switch (this.fijo) {
        // bloquea obj_pv
        case 0:
          this.lock_cant_vend = false
          this.lock_q_vend = false
          this.lock_obj_pv = true
          break

        // bloquea cant_vend
        case 1:
          this.lock_cant_vend = true
          this.lock_q_vend = false
          this.lock_obj_pv = false
          break

        // bloquea q_vend
        case 2:
          this.lock_cant_vend = false
          this.lock_q_vend = true
          this.lock_obj_pv = false
          break
      }
    }
  }
}
</script>