<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="dialog_nuevo = true"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                 <v-col cols="12" sm="6" md="2" class="py-1">
                  Periodo
                  <FechaPickerMes
                    :fecha.sync="filtro.periodo"
                    :clearable="false"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="$store.state.sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="get_locales_x_suc()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" class="py-1">
                  Punto de venta
                  <v-autocomplete
                    v-model="filtro.locales"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="locales_x_suc"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Familia de objetivos
                  <v-autocomplete
                    v-model="filtro.familia"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="familias"
                    hide-details
                    outlined
                    dense
                    @change="get_tipos(); get_locales_x_suc()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" class="py-1">
                  Tipo de objetivo
                  <v-autocomplete
                    v-model="filtro.tipos"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="tipos"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <SelectedCounter
        :items.sync="obj_ptosvta"
        :selected.sync="selected"
      />
      
      <v-data-table
        v-model="selected"
        class="cebra elevation-2 mt-2"
        item-key="id"
        :headers="headers"
        :items="obj_ptosvta"
        :loading="load"
        show-select
        dense
        @toggle-select-all="selectAllToggle"
      >
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <!-- ultima row para el total -->
        <template slot="body.append">
          <tr class="font-weight-bold">
            <th>Total</th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-right">
              {{ total_vend }}
            </th>
            <th></th>
            <th class="text-right">
              {{ total_obj }}
            </th>
            <th></th>
          </tr>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
      <v-row cols="12" class="d-flex justify-end mx-0 my-1">
        <v-btn
          color="primary"
          class="mt-2 ml-2"
          :disabled="selected.length < 1 || !mayor_ultimo_mes"
          @click="dialog = true; prox_mes = false"
        >
          <v-icon left>fas fa-pen</v-icon>
          Editar
        </v-btn>
        <BtnConfirmar
          nombre="Eliminar"
          icono="fas fa-trash"
          color="error"
          :texto="`¿Desea eliminar ${selected.length == 1 ? 'el' : 'los'} ${selected.length == 1 ? '' : selected.length}
                    objetivo${selected.length == 1 ? '' : 's'} seleccionado${selected.length == 1 ? '' : 's'}?`"
          clase="mt-2 ml-2"
          :disabled="selected.length < 1 || !mayor_mes_actual"
          @action="eliminar"
        />
        <v-btn
          color="success"
          class="mt-2 ml-2"
          :disabled="selected.length < 1"
          @click="dialog = true; prox_mes = true"
        >
          <v-icon left>fas fa-copy</v-icon>
          Copiar objetivos prox mes
        </v-btn>
      </v-row>  
    </v-col>

    <ObjPtoVta
      v-model="dialog"
      :all_ptosvta.sync="obj_ptosvta"
      :ptosvta="selected"
      :copiar="prox_mes"
      @action="calcular_totales"
    />

    <NuevoObjPtoVta
      v-model="dialog_nuevo"
      :key="componentKey"
      :familias="familias"
    />

  </v-row>
</template>

<script>
import { get_periodo, get_last_periodo, format_money, format_2_decimales } from '../../util/utils'
import moment from 'moment'
import FechaPickerMes from '../../components/util/FechaPickerMes'
import BtnFiltro from '../../components/util/BtnFiltro'
import SelectedCounter from '../../components/util/SelectedCounter'
import ObjPtoVta from '../../components/objetivos/ObjPtoVta'
import NuevoObjPtoVta from '../../components/objetivos/NuevoObjPtoVta'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      panel: 0,
      total_obj: 0,
      total_vend: 0,
      meses_modif: 2,
      componentKey: 0, // refresca el componente NuevoObjPtoVta
      periodo_actual: get_periodo(),
      mayor_ultimo_mes: false,
      mayor_mes_actual: false,
      prox_mes: false,
      dialog: false,
      dialog_nuevo: false,
      load: false,
      obj_ptosvta: [],
      locales_x_suc: [],
      familias: [],
      tipos: [],
      selected: [],
      headers: [
        { text: 'Sucursal', value: 'sucursal_nom' },
        { text: 'Pto Vta', value: 'local_nom' },
        { text: 'Periodo', value: 'periodo', divider: true },
        { text: 'Cant Vend', value: 'cant_vend', align: 'end', formatter: format_2_decimales },
        { text: 'Q Vend', value: 'q_vend', align: 'end', formatter: format_money },
        { text: 'Obj PV', value: 'obj_pv', align: 'end', formatter: format_money, divider: true },
        { text: 'Tipo', value: 'tipo_nom' },
      ],
      filtro: {
        periodo: get_periodo(),
        sucursal: null,
        locales: [],
        familia: 3,
        tipos: null,
      },
    }
  },
  created () {
    if (this.$store.state.sucursales.length === 1) {
      this.filtro.sucursal = this.$store.state.sucursales[0].id
      this.get_locales_x_suc()
    }
    this.get_data()
  },
  computed: {
    ...mapState('vendedores', ['locales_user'])
  },
  components: {
    FechaPickerMes,
    BtnFiltro,
    SelectedCounter,
    ObjPtoVta,
    NuevoObjPtoVta,
    BtnConfirmar,
  },
  watch: {
    dialog_nuevo (val) {
      if (val) {
        (this.componentKey += 1)
      }
    },
  },
  methods: {
    async eliminar () {
      let errores = 0
      let mensajes = ''
      
      this.$store.state.loading = true
      for (let index = 0; index < this.selected.length; index++) {
        const item = this.selected[index]

        await this.$store.dispatch('objetivos/eliminar_obj_ptovta', { id: item.id })
          .then(() => {
            // eliminado correctamente, lo quita de obj_ptosvta
            const index = this.obj_ptosvta.indexOf(item)
            this.obj_ptosvta.splice(index, 1)
          })
          .catch(error => {
            errores += 1
            mensajes += `<p>${errores}. ${item.local_nom} - ${item.tipo_nom}: ${error.message}</p>`
          })
      }
      this.$store.state.loading = false

      // formatea el mensaje
      const len = this.selected.length
      let icono = 'success'
      let mensaje = `Objetivo${len > 1 ? 's' : ''} eliminado${len > 1 ? 's' : ''} correctamente`

      if (errores > 0) {
        icono = 'warning'
        mensaje = `<span style="font-size: 24px">Objetivos eliminados correctamente: ${len - errores}
                  <div style="margin-top: 12px">Ocurrieron los siguientes errores:</div></span>`
      }

      // limpia la seleccion
      this.selected = []

      // vuelve a calcular los totales
      this.calcular_totales()

      // muestra el mensaje
      this.$swal.fire({
        icon: icono,
        title: mensaje,
        html: mensajes,
      })

    },
    async buscar () {
      this.comparar() // verifica si el periodo del filtro es menor o mayor que el del ultimo mes
      this.load = true
      this.obj_ptosvta = []
      this.selected = []
      this.total_obj = 0
      this.total_vend = 0
      await this.$store.dispatch('objetivos/get_obj_ptosvta', this.filtro)
        .then(res => {
          this.obj_ptosvta = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.calcular_totales()
      this.load = false
    },
    async get_data () {
      this.$store.state.loading = true
      this.familias = []
      await this.$store.dispatch('objetivos/get_familias')
        .then(res => {
          this.familias = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      await this.$store.dispatch('objetivos/get_meses')
        .then(res => {
          this.meses_modif = parseInt(res)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      await this.get_tipos()
      this.$store.state.loading = false
    },
    async get_tipos () {
      this.$store.state.loading = true
      this.tipos = []
      this.filtro.tipos = null
      await this.$store.dispatch('objetivos/get_tipos', this.filtro.familia)
        .then(res => {
          this.tipos = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async limpiar () {
      this.selected = []
      this.locales_x_suc = []
      this.filtro = {
        periodo: get_periodo(),
        sucursal: null,
        locales: [],
        familia: 3,
        tipos: null,
      }
      await this.get_tipos()
    },
    comparar () {
      const periodo = this.filtro.periodo
      this.mayor_ultimo_mes = moment(periodo).isAfter(get_last_periodo(this.meses_modif + 1))
      this.mayor_mes_actual = moment(periodo).isAfter(get_last_periodo())
    },
    calcular_totales () {
      this.total_obj = 0
      this.total_vend = 0
      this.obj_ptosvta.forEach(item => {
        this.total_obj += parseFloat(item.obj_pv)
        this.total_vend += parseFloat(item.cant_vend)
      })
      this.total_obj = format_money(this.total_obj)
      this.total_vend = format_2_decimales(this.total_vend)
    },
    get_locales_x_suc () {
      const familia = this.familias.find(fam => fam.id == this.filtro.familia)
      let categoria = null
      if (familia) categoria = familia.categoria
      this.locales_x_suc = this.locales_user.filter(local => local.sucursal === this.filtro.sucursal && local.empresa === 2 && local.categoria == categoria)
      this.filtro.locales = []
    },
    selectAllToggle () {
      if (this.selected.length < this.obj_ptosvta.length) this.selected = [...this.obj_ptosvta]
      else this.selected = []
    }
  }
}
</script>